/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAppState, ZustandAppState } from "./app-state-context";
import { EventEmitter } from "./event-emitter";

export interface ComponentProps<
  ComponentEvent,
  ComponentEventPayload = undefined,
> {
  fireEvent?: (event: ComponentEvent, payload?: ComponentEventPayload) => void;
}

/**
 * A React component that can be used as a Pimo component.
 */
export type PimoReactComponent<
  ComponentState,
  ComponentEventName = "",
  ComponentEventPayload = undefined,
> = React.FC<
  ComponentState & ComponentProps<ComponentEventName, ComponentEventPayload>
>;

export type ComponentDefinition<
  ComponentState = undefined,
  ComponentEventName = "",
  ComponentEventPayload = undefined,
  LayoutProps = Record<string, unknown> | never,
> = {
  component:
    | PimoReactComponent<
        ComponentState,
        ComponentEventName,
        ComponentEventPayload
      >
    | PimoClassComponent<
        ComponentState,
        ComponentEventName,
        ComponentEventPayload
      >;

  layoutProps?: LayoutProps;
};

export type ComponentEvent<ComponentEventName = string> = {
  eventName: ComponentEventName;
} & Record<string | number, unknown>;

export type DerivePimoReactComponentProps<Component> =
  Component extends PimoReactComponent<infer Props, any> ? Props : never;

export class Component<
  AppState,
  ComponentState,
  ComponentEventName,
  ComponentEventPayload = undefined,
  LayoutProps = any,
> extends EventEmitter<ComponentEventName, ComponentEventPayload> {
  private stateMapper: (appState: AppState) => ComponentState = () =>
    ({}) as ComponentState;

  visibilityMapper?: (appState: AppState) => boolean;

  private renderedComponent?: React.FC;
  public layoutProps?: LayoutProps;

  constructor(
    private definition: ComponentDefinition<
      ComponentState,
      ComponentEventName,
      ComponentEventPayload,
      LayoutProps
    >
  ) {
    super();
    this.layoutProps = definition.layoutProps;
  }

  public render(): React.FC {
    const boundFireEvent = this.fireEvent.bind(this);
    if (!this.renderedComponent) {
      const ComponentToRender = this.getComponentToRender(
        this.definition.component
      );

      const RenderedComponent = () => {
        const componentState = useAppState(
          this.stateMapper as (state: ZustandAppState) => ComponentState
        );

        const isComponentVisible = this.visibilityMapper
          ? // eslint-disable-next-line react-hooks/rules-of-hooks
            useAppState(
              this.visibilityMapper as (state: ZustandAppState) => boolean
            )
          : true;

        if (!componentState || !isComponentVisible) {
          return null;
        }

        return (
          <ComponentToRender {...componentState} fireEvent={boundFireEvent} />
        );
      };
      this.renderedComponent = RenderedComponent;
    }

    return this.renderedComponent;
  }

  getComponentToRender(
    component:
      | PimoClassComponent
      | PimoReactComponent<
          ComponentState,
          ComponentEventName,
          ComponentEventPayload
        >
  ) {
    if (this.isClassComponent(component)) {
      return component.render();
    }

    return component;
  }

  private isClassComponent(
    component:
      | PimoClassComponent
      | PimoReactComponent<
          ComponentState,
          ComponentEventName,
          ComponentEventPayload
        >
  ): component is PimoClassComponent {
    return (component as PimoClassComponent).render != null;
  }

  public mapState(mapper: (appState: AppState) => ComponentState) {
    this.stateMapper = mapper;
  }

  public mapVisibility(mapper: (appState: AppState) => boolean) {
    this.visibilityMapper = mapper;
  }
}

export abstract class PimoClassComponent<
  ComponentState = any,
  ComponentEventNames = any,
  ComponentEventPayload = any,
> {
  abstract render(): PimoReactComponent<
    ComponentState,
    ComponentEventNames,
    ComponentEventPayload
  >;
}

export type PimoComponent<
  ComponentState,
  ComponentEventName = "",
  ComponentEventPayload = unknown,
> =
  | PimoReactComponent<
      ComponentState,
      ComponentEventName,
      ComponentEventPayload
    >
  | PimoClassComponent<
      ComponentState,
      ComponentEventName,
      ComponentEventPayload
    >;
