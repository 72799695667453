import { App, UniversalAppState } from "@pimo/pimo-app-builder";
import { DefaultOverlayNotificationPayload } from "@pimo/pimo-components";
import { OE } from "crq-types";
import { generatePath, matchPath } from "react-router-dom";

import { NotificationsPlugin } from "../../../../packages/pimo-notifications-plugin/dist";
import type { CRQAppState } from "./app";
import { CRQOverlay } from "./components/overlay/crq-overlay";
import {
  type CRQOverlayPartialAppState,
  initialCRQOverlayState,
} from "./components/overlay/crq-overlay-initial-state";
import { APP_ROUTES } from "./constants";
import { fetchLogs } from "./helpers/fetch-logs";

export type OverlayPartialAppState = {
  OEs: OE[];
} & CRQOverlayPartialAppState &
  UniversalAppState;

export const initialOverlayAppState = {
  ...initialCRQOverlayState,
  OEs: [],
  isLoading: false,
} satisfies OverlayPartialAppState;

export const createOverlay = (app: App<CRQAppState>) => {
  const view = app.createOverlayView({ name: "overlay" });
  const overlay = view.addComponent({ component: CRQOverlay });

  overlay.on("overlay:toggle-sidebar", () => {
    const state = app.getAppState();

    app.patchAppState({
      isSidebarOpen: !state.isSidebarOpen,
    });
  });

  overlay.on("overlay:show-logs", async () => {
    const logs = await fetchLogs();

    app.patchAppState({ logs });
  });

  overlay.on("overlay:click-logo", () => {
    app.navigate(`/`);
  });

  overlay.on("overlay:settings-click", () => {
    app.navigate(APP_ROUTES.settingsManageOEs);
    app.patchAppState({ isSidebarOpen: false });
  });

  overlay.on("overlay:notification-read", (params) => {
    const notificationsPlugin = app.getPluginByName<
      NotificationsPlugin<CRQAppState>
    >("NotificationsPlugin");
    void notificationsPlugin?.handleOverlayNotificationReadEvent(
      params as { payload: DefaultOverlayNotificationPayload }
    );
  });

  overlay.mapState(
    ({ isSidebarOpen, OEs, program, userProfile, banner, logs }) => ({
      hasSearchFunctionality: true,
      header: {
        logo: {
          big: "allianz.svg",
        },
        username: `${userProfile?.email ?? "n/a"}`,
        enableLogs: true,
        onLogsShow: () => {
          overlay.fireEvent("overlay:show-logs");
        },
      },
      logs: logs ?? [],
      menuEntries: [
        {
          icon: "clipboard.svg",
          level: 0,
          link: APP_ROUTES.groupDashboard,
          title: "Group Dashboard",
          onlyForAdmin: false,
        },

        {
          icon: "clipboard.svg",
          level: 0,
          link: APP_ROUTES.overview,
          title: "OE Overview",
          onlyForAdmin: false,
        },

        {
          icon: "barchart.svg",
          items: OEs.map(({ name, id }) => {
            return {
              level: 1,
              link: generatePath(APP_ROUTES.report, { id: String(id) }),
              active: !!matchPath(
                `${generatePath(APP_ROUTES.report, { id: String(id) })}/*`,
                window.location.pathname
              ),
              title: name ?? "",
            };
          }),
          level: 0,
          title: "OE Reports",
        },
      ],
      isSidebarOpen,
      reportingDate: program?.reportingDate,
      searchFunction: (items, query) => {
        if (!items?.length) {
          return [];
        }

        const lowerCaseQuery = query?.toLowerCase();

        return OEs.filter(
          ({ name, region }) =>
            name?.toLowerCase().includes(lowerCaseQuery) ||
            region?.regionName?.toLowerCase()?.includes(lowerCaseQuery)
        ).map(({ name, id }) => ({
          level: 1,
          link: generatePath(APP_ROUTES.report, { id: String(id) }),
          active: !!matchPath(
            `${generatePath(APP_ROUTES.report, { id: String(id) })}/*`,
            window.location.pathname
          ),
          title: name ?? "",
        }));
      },
      userProfile,
      notifications: {
        banner,
      },
    })
  );

  return overlay;
};
